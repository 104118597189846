'use client'; // ← Agregar esta línea en la parte superior
import { FC } from 'react';
import { Col, Container, Row } from 'reactstrap';

import { useAppSelector } from '../stores/store';

const Footer: FC = () => {
  const key = useAppSelector<string>(state => state.shared.key);
  return (
    <footer
      className={`ftco-footer ftco-bg-dark ${key !== 'Home' ? '' : 'ftco-section'}`}
      style={{ padding: '3em 0 0 0', height: '100px' }}
    >
      <Container>
        <div className="row mb-5">
          <div className="col-md">
            <div className="ftco-footer-widget mb-4">
              <img
                src="/img/logo2.png"
                className="img-thumbnail"
                style={{ backgroundColor: 'transparent', border: '0' }}
                alt="logo-remax"
              />
              <h2 className="ftco-heading-2">Líder mundial en servicios inmobiliarios</h2>
            </div>
          </div>
          <div className="col-md">
            <div className="ftco-footer-widget mb-4 ml-md-5">
              <h2 className="ftco-heading-2">Corredores</h2>
              <ul className="list-unstyled">
                <li>
                  <a
                    className="py-2 d-block"
                    style={{ color: '#fff' }}
                    href="https://www.remax.com.ar/broker/emanuel-dzierza"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Emanuel Dzierza. <br /> Corredor Público Inmo. <br /> CMQ 1221
                  </a>
                </li>
                <li>
                  <a
                    className="py-2 d-block"
                    style={{ color: '#fff' }}
                    href="https://www.remax.com.ar/agent/giselle-crets-1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Giselle Crets. <br /> Corredor Público Inmo. <br /> CPI 9393
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">Donde estamos</h2>
              <iframe
                title="video"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3279.422097838937!2d-58.25567932381983!3d-34.71975337291289!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a32e40a0c20fe1%3A0x9135b1a0f0f21fa5!2sAv.%20Rivadavia%20430%2C%20B1878%20Quilmes%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses!2sar!4v1724077386885!5m2!1ses!2sar"
                width="300"
                height="225"
                style={{ border: '0' }}
                allowFullScreen={false}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className="col-md">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">Conocenos</h2>
              <div className="block-23 mb-3">
                <ul>
                  <li>
                    <span className="icon icon-map-marker"></span>
                    <span className="text" style={{ color: '#fff' }}>
                      Av. Rivadavia 430, B1878 Quilmes, Provincia de Buenos Aires, Argentina
                    </span>
                  </li>
                  <li>
                    <a href="tel:+5491142534987">
                      <span className="icon icon-phone"></span>
                      <span className="text" style={{ color: '#fff' }}>
                        011 4253-4987
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="ftco-footer-widget mb-12">
              <h2 className="ftco-heading-2">Legales</h2>
              <div className="block-23 mb-3" style={{ color: '#fff' }}>
                &nbsp;&nbsp;&nbsp;En cumplimiento de la leyes provinciales vigentes que regulan el corretaje
                inmobiliario, Ley Nacional 25.028, Ley 22.802 de Lealtad Comercial, Ley 24.240 de Defensa al Consumidor,
                las normas del Código Civil y Comercial de la Nación y Constitucionales, los agente NO ejercen el
                corretaje inmobiliario. Todas las operaciones inmobiliarias son objeto de intermediación y conclusión
                por parte de los martilleros y corredores colegiados, cuyos datos se exhiben en la página.
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Row style={{ backgroundColor: '#343533' }}>
        <Col md={12} className="d-flex justify-content-center align-items-center" style={{ height: '50px' }}>
          <p style={{ margin: '0', color: 'white' }}>
            <a href="https://www.instagram.com/ag_soft/">Sitio Web desarrollado por &copy; AG SOFT</a>
          </p>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
