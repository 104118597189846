import Divider from '@mui/material/Divider';
import { FC } from 'react';
import Carousel from 'react-multi-carousel';
import { Container } from 'reactstrap';
import { useMediaQuery } from 'react-responsive';

import 'react-multi-carousel/lib/styles.css';
import { Agent } from '../models/Agent';
import Contact from './subComponents/contact.component';
import Title from './subComponents/title.component';
import { OFICINA } from '../config/constants';

const Agentes: FC<{
  agentes: Agent[];
}> = ({ agentes }) => {
  const agent = (agent: Agent) => {
    return (
      <div className="staff" key={agent.id} id="agentes" style={{ margin: '15px' }}>
        <div style={{ textAlign: 'center' }}>
          <img
            src={`${agent.image}`}
            className="img-fluid img-thumbnail img-agent"
            alt={`${OFICINA} ${agent.name}`}
          ></img>
        </div>
        <div className="text pt-4" style={{ margin: '5px' }}>
          <h3>
            <a href="https://google.com.ar">{agent.name}</a>
          </h3>
          {/* <span className="position mb-2">{agent.listing}</span> */}
          <Divider />
          {/* <p>{agent.description}</p> */}
          <Contact
            phone={agent.phone}
            textWhastapp={
              agent.phone
                ? `https://wa.me/${agent.phone}?text=Hola, ${agent.name} te contacto desde la pagina de ${OFICINA}`
                : undefined
            }
            textEmail={
              agent.email
                ? `mailto:${agent.email}?subject=${agent.description}&body=Hola ${agent.name}, 
    te contacto desde la pagina de ${OFICINA}`
                : undefined
            }
          />
        </div>
      </div>
    );
  };
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 769px) and (max-width: 1024px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

  const getSlidesToSlide = (): number => {
    if (isMobile) {
      return 1;
    }
    if (isTablet) {
      return 2;
    }
    return 3;
  };

  return (
    <section id="agentes" className="ftco-section bg-light" style={{ padding: '3em 0' }}>
      <Title title="Nuestro equipo" />
      <Container>
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlay
          autoPlaySpeed={2500}
          centerMode={false}
          className=""
          containerClass="container"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite={false}
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          partialVisible
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024
              },
              items: 4,
              partialVisibilityGutter: 40
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0
              },
              items: 1,
              partialVisibilityGutter: 30
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464
              },
              items: 2,
              partialVisibilityGutter: 30
            }
          }}
          rewind={true}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          // showDots
          sliderClass=""
          slidesToSlide={getSlidesToSlide()}
          swipeable
        >
          {agentes.length > 0 &&
            agentes.map((p, k) =>
              agent({
                ...p,
                listing: '166 publicaciones',
                description: 'Agente inmobiliario'
              })
            )}
        </Carousel>
      </Container>
    </section>
  );
};
export default Agentes;
