import { FC } from 'react';
import { Container } from 'reactstrap';

import { OFICINA } from '../../config/constants';

const Title: FC<{ title: string }> = ({ title }) => {
  return (
    <Container>
      <div className="row justify-content-center mb-5 pb-3">
        <div className="col-md-7 heading-section text-center ftco-animate2">
          <span className="subheading">{OFICINA}</span>
          <h2 className="mb-4 font-azul-sec">{title}</h2>
        </div>
      </div>
    </Container>
  );
};

export default Title;
