import { useRef } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Blogs from './pages/blogs';
import Contact from './pages/contact';
import Home from './pages/home';
import Layout from './pages/layout';
import NoPage from './pages/noPage';
import Sumate from './pages/sumate';

const loading = <div>loading ...</div>;

const AppRoutes = () => {
  const teamsRef = useRef<any>(null);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          {/* <Route path="blogs" element={<Blogs />} /> */}
          <Route path="/sumate" element={<Sumate />} />
          <Route path="/:descripcion?/:localidad?/:operacion?" element={<Home />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
