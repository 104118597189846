import { FC } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { styled } from 'styled-components';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ApartmentIcon from '@mui/icons-material/Apartment';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import SchoolIcon from '@mui/icons-material/School';

import 'react-multi-carousel/lib/styles.css';
import { OFICINA } from '../../config/constants';
import { rojoRemaxAlt } from '../../styled/const';

const Title = styled.h3`
  font-size: 48px;
  font-weight: 600;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.2em;
  letter-spacing: 0px;
  word-spacing: 0em;
  color: #000;
  font-family: 'Gotham Medium';
`;

const iconStyle = { color: rojoRemaxAlt, margin: '0px 10px 0px 0px' };

const imgStyle = {
  width: '100%',
  height: 'auto',
  maxWidth: '300px',
  margin: '10px',
  objectFit: 'cover' as 'fill' | 'contain' | 'cover' | 'none' | 'scale-down'
};

const Oportunidades: FC<{ isMobile: boolean; isTablet: boolean; isDesktop: boolean }> = ({
  isMobile,
  isTablet,
  isDesktop
}) => {
  const listStyle = { fontSize: '19px' };
  return (
    <section id="opu" style={{ padding: '1em 0' }} className="bg-light">
      <Container className="mt-5 mb-5">
        <Row>
          <Col
            sm={{ order: 2, size: 12 }}
            md={{ order: 2, size: 12 }}
            lg={{ order: 1, size: 6 }}
            className="d-flex fadeInUp flex-wrap justify-content-center custom-img-container"
          >
            <Row>
              <Col
                md={6}
                lg={6}
                sm={6}
                className="d-flex fadeInUp flex-wrap justify-content-center custom-img-container"
              >
                <img src={`/img/apu1.jpg`} alt="Imagen 1" className="img-fluid" style={imgStyle} />
                <img src={`/img/apu2.jpg`} alt="Imagen 2" className="img-fluid" style={imgStyle} />
              </Col>
              <Col
                md={6}
                lg={6}
                sm={6}
                className="d-flex fadeInUp flex-wrap justify-content-center custom-img-container"
              >
                <img src={`/img/apu3.jpg`} alt="Imagen 3" className="img-fluid" style={imgStyle} />
                <img src={`/img/apu4.jpg`} alt="Imagen 4" className="img-fluid" style={imgStyle} />
              </Col>
            </Row>
          </Col>

          <Col
            sm={{ order: 1, size: 12 }}
            md={{ order: 1, size: 12 }}
            lg={{ order: 2, size: 6 }}
            className={`fadeInUp ftco-animated ${isTablet ? 'sum-tablet' : ''}`}
          >
            <Title>Ser Agente Remax</Title>

            <div>
              <p style={{ color: '#324A6D;' }}>
                Como miembro de <strong> {`${OFICINA}.`}</strong>, tendrás la posibilidad de:
              </p>{' '}
              <div className="elementor-widget-container">
                <ul style={{ listStyle: 'none' }}>
                  <li style={listStyle}>
                    <AttachMoneyIcon style={iconStyle} />
                    <span className="elementor-icon-list-text">Obtener ingresos en dólares.</span>
                  </li>
                  <li style={listStyle}>
                    <ApartmentIcon style={iconStyle} scale={'large'} />
                    <span className="elementor-icon-list-text">
                      Construir una carrera lucrativa con diversos beneficios.
                    </span>
                  </li>
                  <li style={listStyle}>
                    <MoreTimeIcon style={iconStyle} />
                    <span className="elementor-icon-list-text">
                      Administrar tu tiempo y tu cartera de clientes de manera independiente.
                    </span>
                  </li>
                  <li style={listStyle}>
                    <SchoolIcon style={iconStyle} />
                    <span className="elementor-icon-list-text">
                      Capacitarte con nosotros y avanzar profesionalmente junto a expertos del sector inmobiliario.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Oportunidades;
