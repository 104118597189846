import { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { animateScroll as scroll } from 'react-scroll';

import Footer from '../componentes/footer.component';
import { Menu } from '../componentes/subComponents/menu.component';
import { closeToast, ToastSlice } from '../stores/sharedSlice';
import { useAppDispatch, useAppSelector } from '../stores/store';

const Layout: FC = () => {
  const loading = useAppSelector<boolean>(state => state.shared.loading);
  const openToast = useAppSelector<boolean>(state => state.shared.openToast);
  const toastNoti = useAppSelector<ToastSlice>(state => state.shared.toast);
  const dispatch = useAppDispatch();

  const notify = () => {
    switch (toastNoti.type) {
      case 'SUCCESS':
        toast.success(toastNoti.message);
        break;
      case 'ERROR':
        toast.error(toastNoti.message);
        break;
      case 'INFO':
        toast.info(toastNoti.message);
        break;
      case 'WARN':
        toast.warn(toastNoti.message);
        break;
      default:
        toast.success(toastNoti.message);
        break;
    }
    dispatch(closeToast());
  };

  useEffect(() => {
    if (openToast) {
      notify();
    }
  }, [openToast]);

  return (
    <>
      <Menu />
      <nav
        className="d-flex justify-content-between align-items-start logo"
        style={{ position: 'absolute', width: '100%', marginTop: '20px', zIndex: '1', padding: '2px' }}
      >
        <img src="/img/logo2.png" className="img-thumbnail logo-remax" alt="logo-remax" />
      </nav>
      <Outlet />
      <Footer />
      {/* <Backdrop sx={theme => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <ToastContainer position="top-center" theme="light" />
    </>
  );
};

export default Layout;
