import { FC, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import 'react-multi-carousel/lib/styles.css';
import { OFICINA } from '../config/constants';
import { Box, TextSplit } from '../styled/intro';

const Intro: FC = () => {
  const [isBackgroundVisible, setIsBackgroundVisible] = useState(true);
  const [isVisible, setIsVisible] = useState(false);

  const [sizeBox, setSizeBox] = useState<number>(75);
  const [titleButtom, setTitleButtom] = useState<number>(255);
  const [sizeTitle, setSizeTitle] = useState<number>(25);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 769px) and (max-width: 1024px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

  useEffect(() => {
    if (isMobile) {
      setSizeBox(30);
      setTitleButtom(55);
      setSizeTitle(18);
    } else if (isTablet) {
      setSizeBox(50);
      setTitleButtom(50);
      setSizeTitle(55);
    } else {
      setSizeBox(75);
      setTitleButtom(255);
      setSizeTitle(80);
    }
  }, [isMobile, isTablet, isDesktop]);

  // Después de 2 segundos, cambia el estado para activar la transición
  useEffect(() => {
    setIsVisible(true);
    const timer = setTimeout(() => {
      setIsBackgroundVisible(false);
    }, 4000); // 2 segundos

    // Limpiar el timer en caso de que el componente se desmonte
    return () => clearTimeout(timer);
  }, []);

  return (
    <section
      className={`video-container-intro main embed-responsive embed-responsive-16by9 video-height background-visible`}
    >
      <TextSplit
        $size={sizeTitle}
        $bottom={titleButtom}
        className={`animated-text ${isVisible ? 'slide-in' : ''} ${!isBackgroundVisible ? 'iframe-hidden' : 'iframe-visible'}`}
      >
        LÍDERES MUNDIALES EN SERVICIOS INMOBILIARIOS
      </TextSplit>
      <div className="overlay-intro"></div>
      <iframe
        className={`iframe-intro ${isBackgroundVisible ? 'iframe-hidden' : 'iframe-visible'}`}
        // width="560"
        // height="315"
        src="https://www.youtube.com/embed/86dZr8Ufr14?playlist=86dZr8Ufr14&autoplay=1&mute=1&loop=1&controls=0&rel=0&disablekb=1&fs=1"
        // src="https://www.youtube.com/embed/CuEAFJmOE8w?playlist=CuEAFJmOE8w&autoplay=1&mute=1&loop=1&controls=0&rel=0&disablekb=1&fs=1"
        title={OFICINA}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      <Box $alpha={0.6} $col={1} $row={4} $size={sizeBox} />

      <Box $alpha={0.6} $col={2} $row={3} $size={sizeBox} />
      <Box $alpha={0.8} $col={1} $row={3} $size={sizeBox} />

      <Box $alpha={0.6} $col={5} $row={2} $size={sizeBox} />
      <Box $alpha={0.6} $col={4} $row={2} $size={sizeBox} />
      <Box $alpha={0.85} $col={3} $row={2} $size={sizeBox} />
      <Box $alpha={1} $col={2} $row={2} $size={sizeBox} />
      <Box $alpha={1} $col={1} $row={2} $size={sizeBox} />

      <Box $alpha={0.6} $col={6} $row={1} $size={sizeBox} />
      <Box $alpha={0.85} $col={5} $row={1} $size={sizeBox} />
      <Box $alpha={1} $col={4} $row={1} $size={sizeBox} />
      <Box $alpha={1} $col={3} $row={1} $size={sizeBox} />
      <Box $alpha={1} $col={2} $row={1} $size={sizeBox} />
      <Box $alpha={1} $col={1} $row={1} $size={sizeBox} />
    </section>
  );
};
export default Intro;
