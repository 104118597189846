import { FC } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { styled } from 'styled-components';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';

import 'react-multi-carousel/lib/styles.css';
import { OFICINA } from '../../config/constants';
import { azulRemax, rojoRemaxAlt } from '../../styled/const';
const Title = styled.h3`
  font-size: 48px;
  font-weight: 600;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.2em;
  letter-spacing: 0px;
  word-spacing: 0em;
  color: #fff;
  font-family: 'Gotham Medium';
  text-align: center;
  margin-bottom: 3px;
`;

const DescItems = styled.strong`
  color: #324a6d;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 300;
  text-transform: none;
  font-style: normal;
  text-decoration: none;
  line-height: 1.5em;
  letter-spacing: 0px;
  word-spacing: 0em;
  color: #fff;
`;

const ContainerItems = styled(Container)`
  text-align: center;
`;

const Formulario: FC<{ isMobile: boolean; isTablet: boolean; isDesktop: boolean }> = ({
  isMobile,
  isTablet,
  isDesktop
}) => {
  return (
    <section id="formulario" style={{ padding: '1em 0', backgroundColor: azulRemax }}>
      <ContainerItems className="mt-5 mb-5">
        <Title>
          Sumate a la familia de <strong> {`${OFICINA}.`}</strong>
        </Title>
        <DescItems>
          Ya seas un joven apasionado con experiencia en el sector o un profesional en busca de nuevas oportunidades,
          completá el formulario con tus datos y nuestro equipo se pondrá en contacto con vos
        </DescItems>
      </ContainerItems>
    </section>
  );
};
export default Formulario;
